a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.nav-item {
  display: flex;
  align-items: center;
}


.flex {
  display: flex;
  &--center{
    @extend .flex;
    align-items: center;
    justify-content: center;
  }
}

.avatar {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }
}